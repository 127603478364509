import React, { Component } from "react"
import { ChevronDown, ChevronUp, Star } from "react-feather"
import { Trans } from "gatsby-plugin-react-i18next"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Container from "../../components/Container"
import Card from "../../components/Card"
import ContactCard from "../../components/ContactCard"

import ServicespageOrnamentDownLeft from "../../images/ornamen-down-left.png"
import ServicespageOrnamentDownRight from "../../images/ornamen-down-right.png"

import ServicesData from "./services.json"

import "./style.scss"

class ServicesPage extends Component {
  state = {
    showService: "",
  }

  handleShowService(name) {
    const { showService } = this.state

    this.setState({
      showService: name === showService ? "" : name,
    })
  }

  render() {
    const { showService } = this.state

    return (
      <Layout>
        <SEO
          title="Services"
          keywords={[
            `provalindonusa`,
            `consultant`,
            `advisory`,
            `properti`,
            `jakarta`,
            `services`,
          ]}
        />
        <div className="servicespage">
          <section className="servicespage__banner">
            <Container className="servicespage__banner-container">
              <h1>
                <Trans>Services Page Title</Trans>
              </h1>
              <p>
                <Trans>Services Page Description</Trans>
              </p>
              <span>
                <Trans>Geser Kebawah</Trans>
              </span>
              <ChevronDown color="#1F3961" />
            </Container>
            <img
              src={ServicespageOrnamentDownLeft}
              className="servicespage__banner-ornament-down-left"
              alt="ornament"
            />
            <img
              src={ServicespageOrnamentDownRight}
              className="servicespage__banner-ornament-down-right"
              alt="ornament"
            />
          </section>
          <section className="servicespage__top-service">
            <Container className="servicespage__top-service-container">
              <h2>
                <Trans>Top Services</Trans>
              </h2>
              <div className="servicespage__top-service-list">
                {ServicesData.advisory.map((item, index) => {
                  return item.isTopService ? (
                    <Card
                      key={index}
                      className="mr-20"
                      hasBoxShadow
                      hasBorderRadius
                      paddingSize="large"
                      backgroundColor="secondary"
                    >
                      <h3>{item.name}</h3>
                      <p>
                        <Trans>{item.desc}</Trans>
                      </p>
                    </Card>
                  ) : null
                })}
                {ServicesData.consultant.map((item, index) => {
                  return item.isTopService ? (
                    <Card
                      key={index}
                      className="mr-20"
                      hasBoxShadow
                      hasBorderRadius
                      paddingSize="large"
                      backgroundColor="secondary"
                    >
                      <h3>{item.name}</h3>
                      <p>
                        <Trans>{item.desc}</Trans>
                      </p>
                    </Card>
                  ) : null
                })}
                {ServicesData.business.map((item, index) => {
                  return item.isTopService ? (
                    <Card
                      key={index}
                      className="mr-20"
                      hasBoxShadow
                      hasBorderRadius
                      paddingSize="large"
                      backgroundColor="secondary"
                    >
                      <h3>{item.name}</h3>
                      <p>
                        <Trans>{item.desc}</Trans>
                      </p>
                    </Card>
                  ) : null
                })}
              </div>
            </Container>
          </section>
          <section className="servicespage__all-service">
            <Container>
              <div className="servicespage__all-service-advisory">
                <h2>Advisory</h2>
                {ServicesData.advisory.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="servicespage__all-service-advisory-list"
                    >
                      <div
                        className="name"
                        onClick={() => this.handleShowService(item.name)}
                      >
                        <p>
                          {item.name}
                          {item.isTopService ? (
                            <Star color="#fff500" className="ml-8" />
                          ) : (
                            undefined
                          )}
                        </p>
                        {showService === item.name ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </div>
                      <p
                        className="desc"
                        style={{
                          display: showService === item.name ? "block" : "none",
                        }}
                      >
                        <Trans>{item.desc}</Trans>
                      </p>
                    </div>
                  )
                })}
              </div>
              <div className="servicespage__all-service-consultant">
                <h2>Consultant</h2>
                {ServicesData.consultant.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="servicespage__all-service-consultant-list"
                    >
                      <div
                        className="name"
                        onClick={() => this.handleShowService(item.name)}
                      >
                        <p>
                          {item.name}{" "}
                          {item.isTopService ? (
                            <Star color="#fff500" className="ml-8" />
                          ) : (
                            undefined
                          )}
                        </p>
                        {showService === item.name ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </div>
                      <p
                        className="desc"
                        style={{
                          display: showService === item.name ? "block" : "none",
                        }}
                      >
                        <Trans>{item.desc}</Trans>
                      </p>
                    </div>
                  )
                })}
              </div>
              <div className="servicespage__all-service-business">
                <h2>Valuation</h2>
                {ServicesData.business.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="servicespage__all-service-business-list"
                    >
                      <div
                        className="name"
                        onClick={() => this.handleShowService(item.name)}
                      >
                        <p>
                          {item.name}{" "}
                          {item.isTopService ? (
                            <Star color="#fff500" className="ml-8" />
                          ) : (
                            undefined
                          )}
                        </p>
                        {showService === item.name ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </div>
                      <p
                        className="desc"
                        style={{
                          display: showService === item.name ? "block" : "none",
                        }}
                      >
                        <Trans>{item.desc}</Trans>
                      </p>
                    </div>
                  )
                })}
              </div>
            </Container>
          </section>

          <section className="servicespage__contact">
            <Container>
              <ContactCard
                title="Contact Title"
                subtitle="Contact Description"
              />
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ServicesPage
